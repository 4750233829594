import * as React from "react";
import siteSettings from "../constants/siteSettings";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

const ClientReviews: React.FC = () => {
  return (
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>testimonials</h2>
          <p>{siteSettings.testimonialLine}</p>
        </header>
        <div className="row">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            {siteSettings.clientTestimonials.map((item, index) => (
              <SwiperSlide>
                <div className="testimonial-item">
                  <div className="stars">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div>
                  <p>{item.review}</p>
                  <div className="profile mt-auto">
                    <h3 style={{ textTransform: "capitalize" }}>{item.from}</h3>
                    <h4>{item.designation}</h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ClientReviews;
