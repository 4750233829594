import * as React from "react";
import siteSettings from "../constants/siteSettings";

const Values: React.FC = () => {
  return (
    <section id="values" className="values">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Our Values</h2>
          <p>{siteSettings.valuetagline}</p>
        </header>
        <div className="row">
          {siteSettings.keyValues.map((item, index) => (
            <div
              key={index}
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="box">
                <h3>{item.title}</h3>
                <p>{item.describe}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;
