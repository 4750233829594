import * as React from "react";
import siteSettings from "../constants/siteSettings";

const LetsBuild: React.FC = () => {
  return (
    <section id="letsbuild" className="letsbuild">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>testimonials</h2>
          <p>{siteSettings.letsbuildTitle}</p>
        </header>
        <div className="row">
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="companyName" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder="Your email address"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="emailAddress" className="form-label">
                Official Email
              </label>
              <input
                type="email"
                className="form-control"
                id="emailAddress"
                placeholder="Type in your email address"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                placeholder="Type in your phone number"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="typeOfProject" className="form-label">
                Type of Project
              </label>
              <select
                className="form-control"
                name="typeOfProject"
                id="typeOfProject"
              >
                <option value={0}>Select Project Type</option>
                <option value={1}>Web Development</option>
                <option value={2}>Mobile App Development</option>
                <option value={3}>Web & Mobile App Development</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="describeIdea" className="form-label">
                Describe your Idea
              </label>
              <textarea
                placeholder="Type in your idea of project...."
                className="form-control"
                rows={4}
                name="describeIdea"
                id="describeIdea"
              ></textarea>
            </div>
          </div>
          <div className="col-md-12 text-right about">
            <a href="#" className="btn-read-more d-inline-flex">
              <span>Send Details</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsBuild;
