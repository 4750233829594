import * as React from "react";
import siteSettings from "../constants/siteSettings";
import headerImage from "../assets/img/hero-img.png";

const Hero: React.FC = () => {
  return (
    <section id="hero" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h1>{siteSettings.tagline}</h1>
            <h2>{siteSettings.subTagline}</h2>
            <div>
              <div className="text-center text-lg-start">
                <a
                  href="#about"
                  className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Get Started</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 hero-img">
            <img src={headerImage} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
