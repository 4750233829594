import * as React from "react";
import About from "../components/About";
import ClientReviews from "../components/ClientReviews";
import Hero from "../components/Hero";
import Layout from "../components/layout";
import LetsBuild from "../components/LetsBuild";
import Values from "../components/Values";

// markup
const Index: React.FC = () => {
  return (
    <Layout pageTitle="Light Infotech Software Solutions in Surat, Gujarat India.">
      <div>
        <Hero />
        <main id="main">
          <About />
          <Values />
          <ClientReviews />
          <LetsBuild />
        </main>
      </div>
    </Layout>
  );
};

export default Index;
